import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node'
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
  type ShouldRevalidateFunctionArgs,
} from '@remix-run/react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {
  Button,
  Toaster as ToasterPrimitive,
  TooltipProvider,
  toast,
} from '@translated/babele-react'
import font from '@translated/babele-tokens/assets/arsmaquettepro.css?url'
import {useEffect, useState} from 'react'

import {captureRemixErrorBoundaryError, withSentry} from '@sentry/remix'
import {
  Error401State,
  Error404State,
  Error500State,
} from '~/features/ErrorState'
import tailwind from '~/tailwind.css?url'
import {version} from '../package.json'
import {envVars} from './env.server'
import {Header} from './ui/Header'
import {getToast} from './utils/toast.server'

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: tailwind},
  {rel: 'stylesheet', href: font},
]

export const loader = async ({request}: LoaderFunctionArgs) => {
  const {toast, headers} = await getToast(request)

  const ENV = {
    IS_SENTRY_ENABLED: envVars.IS_SENTRY_ENABLED,
    STRIPE_PUBLISHABLE_KEY: envVars.STRIPE_PUBLISHABLE_KEY,
    GA_TRACKING_ID: envVars.GA_TRACKING_ID,
    IS_MOBILE_AVAILABLE: envVars.IS_MOBILE_AVAILABLE,
    VERSION: version,
    SENTRY_DNS: envVars.SENTRY_DNS,
    SENTRY_REPLAY_SAMPLE_RATE: envVars.SENTRY_REPLAY_SAMPLE_RATE,
    SENTRY_REPLAY_ERROR_SAMPLE_RATE: envVars.SENTRY_REPLAY_ERROR_SAMPLE_RATE,
    MODE: envVars.MODE,
    MMT_TRANSLATE_API_EU_URL: envVars.MMT_TRANSLATE_API_EU_URL,
    IS_HOTJAR_ENABLED: envVars.IS_HOTJAR_ENABLED,
  }

  return json(
    {
      toast,
      ENV,
    },
    {headers},
  )
}

const Toaster = () => {
  const loaderData = useLoaderData<typeof loader>()
  const toastData = loaderData.toast

  useEffect(() => {
    if (!toastData) return
    const {type, title, description} = toastData
    setTimeout(() => {
      toast[type](title, {description})
    }, 0)
  }, [toastData])

  return <ToasterPrimitive position="bottom-right" />
}

export function shouldRevalidate({
  actionResult,
  formAction,
}: ShouldRevalidateFunctionArgs) {
  return actionResult?.status != 'success' || formAction != '/translate'
}

export interface WindowEnvVars {
  SENTRY_DNS?: string
  SENTRY_REPLAY_SAMPLE_RATE?: number
  SENTRY_REPLAY_ERROR_SAMPLE_RATE?: number
  VERSION: string
  IS_SENTRY_ENABLED?: boolean
  MODE?: string
  GA_TRACKING_ID?: string
  MMT_TRANSLATE_API_EU_URL: string
  IS_HOTJAR_ENABLED?: boolean
}

export const Layout = ({children}: {children: React.ReactNode}) => {
  const loaderData = useRouteLoaderData<typeof loader>('root')

  const ENV = loaderData?.ENV
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {ENV?.IS_HOTJAR_ENABLED && (
          <script
            async
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: `
                 (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5044865,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
          />
        )}
        {ENV?.GA_TRACKING_ID && (
          <>
            <script
              suppressHydrationWarning
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];`,
              }}
            />
            <script
              async
              suppressHydrationWarning
              dangerouslySetInnerHTML={{
                __html: `{
              (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
                  var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "${ENV?.GA_TRACKING_ID}");
                ;
            }`,
              }}
            />
          </>
        )}
      </head>
      <body data-theme="mmt-light" className="text-neutral-1000 bg-neutral-25">
        {ENV?.GA_TRACKING_ID && (
          <noscript>
            <iframe
              title="google tag manager"
              src={`https://www.googletagmanager.com/ns.html?id=${ENV.GA_TRACKING_ID}`}
              height="0"
              width="0"
              style={{display: 'none', visibility: 'hidden'}}
            ></iframe>
          </noscript>
        )}
        {children}
        {ENV && (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(ENV)}`,
            }}
          />
        )}
        <Scripts />
      </body>
    </html>
  )
}

export const GlobalErrorState = () => {
  const error = useRouteError()

  const routeError = isRouteErrorResponse(error)
  const is401Error = routeError && error.status == 401
  const is404Error = routeError && error.status == 404

  return (
    <div className="grid grid-rows-[auto_1fr] h-[100dvh]">
      <Header className="px-6 py-3" />
      {is404Error ? (
        <Error404State
          actionsSlot={
            <Button
              variant={{tone: 'primary', size: 'lg'}}
              render={
                <Link to="/" reloadDocument>
                  Back to homepage
                </Link>
              }
            >
              Back to homepage
            </Button>
          }
        />
      ) : is401Error ? (
        Error401State
      ) : (
        Error500State
      )}
    </div>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return <GlobalErrorState />
}

function App() {
  const {ENV} = useLoaderData<typeof loader>()
  const [stripe] = useState(() => loadStripe(ENV.STRIPE_PUBLISHABLE_KEY))
  return (
    <>
      <TooltipProvider>
        <Elements stripe={stripe}>
          <Outlet />
        </Elements>
      </TooltipProvider>
      <Toaster />
      <ScrollRestoration />
    </>
  )
}

export default withSentry(App)
